import HandyCollapse from "handy-collapse";
import "./hoa-collapsible-content.css";

class HoaCollapsibleContent extends HTMLElement {
	constructor() {
		super();

		let namespace = "hc";
		if (this.dataset.namespace) {
			namespace = this.dataset.namespace;
		} else {
			console.warn(
				"HoaCollapsibleContent: No namespace provided. Using default namespace: " +
					namespace
			);
		}

		new HandyCollapse({
			nameSpace: namespace,
			isAnimation: false,
			closeOthers: false,
		});
	}
}

customElements.define("hoa-collapsible-content", HoaCollapsibleContent);
